._1o7wEW_paragraph {
  backdrop-filter: blur(.5px);
  color: #ff9046;
  background-color: #ffffff1f;
  border-radius: 30px;
  max-width: 720px;
  margin: 60px 12px;
  padding: 15px 30px;
  font-size: 28px;
  line-height: 1.8;
}

@media (width <= 425px) {
  ._1o7wEW_paragraph {
    border-radius: 15px;
    width: 300px;
    margin: 30px;
    padding: 12px 24px;
    font-size: 18px;
  }
}

._1o7wEW_info {
  text-align: center;
  color: #ff9046;
  flex-direction: column;
  align-items: center;
  margin: 0 0 60px;
  font-size: 20px;
  display: flex;
}

._1o7wEW_info ul {
  text-align: left;
  background-color: #ffffff1f;
  border: 2px solid #ff9046;
  border-radius: 10px;
  min-width: 320px;
  max-width: 425px;
  margin: 0 0 60px;
  padding: 10px;
}

._1o7wEW_info ul li {
  margin: 0 0 0 15px;
}

._1o7wEW_info ul p {
  text-align: center;
  margin: 0 0 15px;
  font-size: 18px;
}

._1o7wEW_info:last-child {
  margin: 0;
}

@media (width <= 425px) {
  ._1o7wEW_info {
    max-width: 320px;
    font-size: 15px;
  }
}

._1o7wEW_screenshot {
  image-rendering: high-quality;
  border: 1px solid #ff9046;
  border-radius: 36px;
  width: 300px;
  height: 540px;
  margin: 0 30px 60px;
}

._1o7wEW_timeline {
  margin: 0 12px 60px;
}

._1o7wEW_follow {
  margin: 30px;
}

._1o7wEW_layout {
  flex-direction: column;
  min-height: 100vh;
  margin: 0;
  display: flex;
  overflow: hidden;
}

._1o7wEW_page {
  flex-direction: row;
  flex: 1 0 auto;
  margin: 0;
  display: flex;
}

._1o7wEW_main {
  flex-direction: column;
  flex: 1 0 auto;
  margin: 0;
  display: flex;
}

.Jcnh_G_paragraph {
  backdrop-filter: blur(.5px);
  color: #ff9046;
  background-color: #ffffff1f;
  border-radius: 30px;
  max-width: 720px;
  margin: 60px 12px;
  padding: 15px 30px;
  font-size: 28px;
  line-height: 1.8;
}

@media (width <= 425px) {
  .Jcnh_G_paragraph {
    border-radius: 15px;
    width: 300px;
    margin: 30px;
    padding: 12px 24px;
    font-size: 18px;
  }
}

.Jcnh_G_info {
  text-align: center;
  color: #ff9046;
  flex-direction: column;
  align-items: center;
  margin: 0 0 60px;
  font-size: 20px;
  display: flex;
}

.Jcnh_G_info ul {
  text-align: left;
  background-color: #ffffff1f;
  border: 2px solid #ff9046;
  border-radius: 10px;
  min-width: 320px;
  max-width: 425px;
  margin: 0 0 60px;
  padding: 10px;
}

.Jcnh_G_info ul li {
  margin: 0 0 0 15px;
}

.Jcnh_G_info ul p {
  text-align: center;
  margin: 0 0 15px;
  font-size: 18px;
}

.Jcnh_G_info:last-child {
  margin: 0;
}

@media (width <= 425px) {
  .Jcnh_G_info {
    max-width: 320px;
    font-size: 15px;
  }
}

.Jcnh_G_screenshot {
  image-rendering: high-quality;
  border: 1px solid #ff9046;
  border-radius: 36px;
  width: 300px;
  height: 540px;
  margin: 0 30px 60px;
}

.Jcnh_G_timeline {
  margin: 0 12px 60px;
}

.Jcnh_G_follow {
  margin: 30px;
}

.Jcnh_G_header {
  color: #ff9046;
  text-align: center;
  padding: 1rem 0;
  font-family: ultra, Arial, Helvetica, sans-serif;
  font-size: 39px;
}

@media (width <= 425px) {
  .Jcnh_G_header {
    font-size: 36px;
  }
}

.-ZjTbW_paragraph {
  backdrop-filter: blur(.5px);
  color: #ff9046;
  background-color: #ffffff1f;
  border-radius: 30px;
  max-width: 720px;
  margin: 60px 12px;
  padding: 15px 30px;
  font-size: 28px;
  line-height: 1.8;
}

@media (width <= 425px) {
  .-ZjTbW_paragraph {
    border-radius: 15px;
    width: 300px;
    margin: 30px;
    padding: 12px 24px;
    font-size: 18px;
  }
}

.-ZjTbW_info {
  text-align: center;
  color: #ff9046;
  flex-direction: column;
  align-items: center;
  margin: 0 0 60px;
  font-size: 20px;
  display: flex;
}

.-ZjTbW_info ul {
  text-align: left;
  background-color: #ffffff1f;
  border: 2px solid #ff9046;
  border-radius: 10px;
  min-width: 320px;
  max-width: 425px;
  margin: 0 0 60px;
  padding: 10px;
}

.-ZjTbW_info ul li {
  margin: 0 0 0 15px;
}

.-ZjTbW_info ul p {
  text-align: center;
  margin: 0 0 15px;
  font-size: 18px;
}

.-ZjTbW_info:last-child {
  margin: 0;
}

@media (width <= 425px) {
  .-ZjTbW_info {
    max-width: 320px;
    font-size: 15px;
  }
}

.-ZjTbW_screenshot {
  image-rendering: high-quality;
  border: 1px solid #ff9046;
  border-radius: 36px;
  width: 300px;
  height: 540px;
  margin: 0 30px 60px;
}

.-ZjTbW_timeline {
  margin: 0 12px 60px;
}

.-ZjTbW_follow {
  margin: 30px;
}

.-ZjTbW_footer {
  color: #ff9046;
  text-align: center;
  flex-shrink: 0;
  padding: 0 0 30px;
  font-size: 20px;
}

@media (width <= 425px) {
  .-ZjTbW_footer {
    font-size: 15px;
  }
}

.Dppp2a_paragraph {
  backdrop-filter: blur(.5px);
  color: #ff9046;
  background-color: #ffffff1f;
  border-radius: 30px;
  max-width: 720px;
  margin: 60px 12px;
  padding: 15px 30px;
  font-size: 28px;
  line-height: 1.8;
}

@media (width <= 425px) {
  .Dppp2a_paragraph {
    border-radius: 15px;
    width: 300px;
    margin: 30px;
    padding: 12px 24px;
    font-size: 18px;
  }
}

.Dppp2a_info {
  text-align: center;
  color: #ff9046;
  flex-direction: column;
  align-items: center;
  margin: 0 0 60px;
  font-size: 20px;
  display: flex;
}

.Dppp2a_info ul {
  text-align: left;
  background-color: #ffffff1f;
  border: 2px solid #ff9046;
  border-radius: 10px;
  min-width: 320px;
  max-width: 425px;
  margin: 0 0 60px;
  padding: 10px;
}

.Dppp2a_info ul li {
  margin: 0 0 0 15px;
}

.Dppp2a_info ul p {
  text-align: center;
  margin: 0 0 15px;
  font-size: 18px;
}

.Dppp2a_info:last-child {
  margin: 0;
}

@media (width <= 425px) {
  .Dppp2a_info {
    max-width: 320px;
    font-size: 15px;
  }
}

.Dppp2a_screenshot {
  image-rendering: high-quality;
  border: 1px solid #ff9046;
  border-radius: 36px;
  width: 300px;
  height: 540px;
  margin: 0 30px 60px;
}

.Dppp2a_timeline {
  margin: 0 12px 60px;
}

.Dppp2a_follow {
  margin: 30px;
}

.Dppp2a_container {
  color: #ff9046;
  flex-direction: column;
  align-items: center;
  width: 100%;
  display: flex;
}

.Dppp2a_button {
  color: #000;
  background-color: #ff9046;
  border: 0 solid #000;
  border-radius: 1rem;
  min-width: 200px;
  max-width: 200px;
  margin: 1rem 0;
  padding: .5rem;
}

.Dppp2a_response {
  color: #ff9046;
  margin-bottom: 1rem;
}

.Dppp2a_input {
  border: 1px solid #ff9046;
  border-radius: 1rem;
  min-width: 200px;
  max-width: 200px;
  margin: 1rem 0;
  padding: .5rem;
}

.eLjgZW_paragraph {
  backdrop-filter: blur(.5px);
  color: #ff9046;
  background-color: #ffffff1f;
  border-radius: 30px;
  max-width: 720px;
  margin: 60px 12px;
  padding: 15px 30px;
  font-size: 28px;
  line-height: 1.8;
}

@media (width <= 425px) {
  .eLjgZW_paragraph {
    border-radius: 15px;
    width: 300px;
    margin: 30px;
    padding: 12px 24px;
    font-size: 18px;
  }
}

.eLjgZW_info {
  text-align: center;
  color: #ff9046;
  flex-direction: column;
  align-items: center;
  margin: 0 0 60px;
  font-size: 20px;
  display: flex;
}

.eLjgZW_info ul {
  text-align: left;
  background-color: #ffffff1f;
  border: 2px solid #ff9046;
  border-radius: 10px;
  min-width: 320px;
  max-width: 425px;
  margin: 0 0 60px;
  padding: 10px;
}

.eLjgZW_info ul li {
  margin: 0 0 0 15px;
}

.eLjgZW_info ul p {
  text-align: center;
  margin: 0 0 15px;
  font-size: 18px;
}

.eLjgZW_info:last-child {
  margin: 0;
}

@media (width <= 425px) {
  .eLjgZW_info {
    max-width: 320px;
    font-size: 15px;
  }
}

.eLjgZW_screenshot {
  image-rendering: high-quality;
  border: 1px solid #ff9046;
  border-radius: 36px;
  width: 300px;
  height: 540px;
  margin: 0 30px 60px;
}

.eLjgZW_timeline {
  margin: 0 12px 60px;
}

.eLjgZW_follow {
  margin: 30px;
}

.eLjgZW_conatiner {
  color: #ff9046;
  flex-direction: row;
  flex: 1 0;
  margin: 0 auto;
  font-size: .9rem;
  display: flex;
}

@media (width >= 1080px) {
  .eLjgZW_conatiner {
    max-width: 1080px;
  }
}

@media (width <= 800px) {
  .eLjgZW_conatiner {
    max-width: 100%;
    font-size: .66rem;
  }
}

.wnaxWG_paragraph {
  backdrop-filter: blur(.5px);
  color: #ff9046;
  background-color: #ffffff1f;
  border-radius: 30px;
  max-width: 720px;
  margin: 60px 12px;
  padding: 15px 30px;
  font-size: 28px;
  line-height: 1.8;
}

@media (width <= 425px) {
  .wnaxWG_paragraph {
    border-radius: 15px;
    width: 300px;
    margin: 30px;
    padding: 12px 24px;
    font-size: 18px;
  }
}

.wnaxWG_info {
  text-align: center;
  color: #ff9046;
  flex-direction: column;
  align-items: center;
  margin: 0 0 60px;
  font-size: 20px;
  display: flex;
}

.wnaxWG_info ul {
  text-align: left;
  background-color: #ffffff1f;
  border: 2px solid #ff9046;
  border-radius: 10px;
  min-width: 320px;
  max-width: 425px;
  margin: 0 0 60px;
  padding: 10px;
}

.wnaxWG_info ul li {
  margin: 0 0 0 15px;
}

.wnaxWG_info ul p {
  text-align: center;
  margin: 0 0 15px;
  font-size: 18px;
}

.wnaxWG_info:last-child {
  margin: 0;
}

@media (width <= 425px) {
  .wnaxWG_info {
    max-width: 320px;
    font-size: 15px;
  }
}

.wnaxWG_screenshot {
  image-rendering: high-quality;
  border: 1px solid #ff9046;
  border-radius: 36px;
  width: 300px;
  height: 540px;
  margin: 0 30px 60px;
}

.wnaxWG_timeline {
  margin: 0 12px 60px;
}

.wnaxWG_follow {
  margin: 30px;
}

.wnaxWG_container {
  flex-direction: column;
  flex: 1 0;
  width: 100%;
  max-width: 100%;
  display: flex;
}

.wnaxWG_conversation {
  white-space: pre-wrap;
  flex-direction: column;
  flex: 1 0;
  padding: 1rem;
  display: flex;
  overflow: hidden scroll;
}

.wnaxWG_messageContainer {
  flex-direction: column;
  width: 100%;
  margin-bottom: 1.2rem;
  display: flex;
}

.wnaxWG_user {
  align-self: flex-end;
}

.wnaxWG_assistant {
  align-self: flex-start;
}

.wnaxWG_editMessageIcon {
  cursor: pointer;
  background-color: #0000;
  border: none;
}

.wnaxWG_messageEditContainer {
  z-index: 1;
  background-color: #ff9046;
  border: 1px solid #000;
  border-radius: 1rem;
  flex-direction: row;
  justify-content: center;
  align-self: flex-end;
  align-items: center;
  width: min-content;
  margin: .3rem 0 0 1.2rem;
  padding: .12rem;
  display: flex;
}

.wnaxWG_inputContainer {
  justify-content: center;
  align-items: center;
  padding: 1rem;
  display: flex;
}

.wnaxWG_inputContainer textarea {
  background-color: #ffd3b3;
  border: none;
  border-radius: .6rem;
  flex: 1;
  height: 2rem;
  padding: .6rem;
  font-size: .9rem;
  display: flex;
}

@media (width <= 800px) {
  .wnaxWG_inputContainer textarea {
    font-size: .66rem;
  }
}

.wnaxWG_inputContainer textarea:focus {
  outline: none;
}

.wnaxWG_inputContainer button {
  color: #000;
  cursor: pointer;
  background-color: #ff9046;
  border: none;
  border-radius: .6rem;
  height: 2rem;
  margin: 0 .3rem;
  padding: 0 .6rem;
  font-weight: bold;
}

.-glwbG_paragraph {
  backdrop-filter: blur(.5px);
  color: #ff9046;
  background-color: #ffffff1f;
  border-radius: 30px;
  max-width: 720px;
  margin: 60px 12px;
  padding: 15px 30px;
  font-size: 28px;
  line-height: 1.8;
}

@media (width <= 425px) {
  .-glwbG_paragraph {
    border-radius: 15px;
    width: 300px;
    margin: 30px;
    padding: 12px 24px;
    font-size: 18px;
  }
}

.-glwbG_info {
  text-align: center;
  color: #ff9046;
  flex-direction: column;
  align-items: center;
  margin: 0 0 60px;
  font-size: 20px;
  display: flex;
}

.-glwbG_info ul {
  text-align: left;
  background-color: #ffffff1f;
  border: 2px solid #ff9046;
  border-radius: 10px;
  min-width: 320px;
  max-width: 425px;
  margin: 0 0 60px;
  padding: 10px;
}

.-glwbG_info ul li {
  margin: 0 0 0 15px;
}

.-glwbG_info ul p {
  text-align: center;
  margin: 0 0 15px;
  font-size: 18px;
}

.-glwbG_info:last-child {
  margin: 0;
}

@media (width <= 425px) {
  .-glwbG_info {
    max-width: 320px;
    font-size: 15px;
  }
}

.-glwbG_screenshot {
  image-rendering: high-quality;
  border: 1px solid #ff9046;
  border-radius: 36px;
  width: 300px;
  height: 540px;
  margin: 0 30px 60px;
}

.-glwbG_timeline {
  margin: 0 12px 60px;
}

.-glwbG_follow {
  margin: 30px;
}

.-glwbG_loader {
  box-sizing: border-box;
  color: #814d31;
  border: 2px solid;
  border-radius: 50%;
  width: 60px;
  height: 25px;
  margin: 2rem 1rem;
  animation: 2s linear infinite -glwbG_l2;
  display: grid;
}

.-glwbG_loader:before, .-glwbG_loader:after {
  content: "";
  border: inherit;
  animation: inherit;
  border-radius: 50%;
  grid-area: 1 / 1;
  animation-duration: 3s;
}

.-glwbG_loader:after {
  --s: -1;
}

@keyframes -glwbG_l2 {
  100% {
    transform: rotate(calc(var(--s, 1) * 1turn));
  }
}

._5JC__q_paragraph {
  backdrop-filter: blur(.5px);
  color: #ff9046;
  background-color: #ffffff1f;
  border-radius: 30px;
  max-width: 720px;
  margin: 60px 12px;
  padding: 15px 30px;
  font-size: 28px;
  line-height: 1.8;
}

@media (width <= 425px) {
  ._5JC__q_paragraph {
    border-radius: 15px;
    width: 300px;
    margin: 30px;
    padding: 12px 24px;
    font-size: 18px;
  }
}

._5JC__q_info {
  text-align: center;
  color: #ff9046;
  flex-direction: column;
  align-items: center;
  margin: 0 0 60px;
  font-size: 20px;
  display: flex;
}

._5JC__q_info ul {
  text-align: left;
  background-color: #ffffff1f;
  border: 2px solid #ff9046;
  border-radius: 10px;
  min-width: 320px;
  max-width: 425px;
  margin: 0 0 60px;
  padding: 10px;
}

._5JC__q_info ul li {
  margin: 0 0 0 15px;
}

._5JC__q_info ul p {
  text-align: center;
  margin: 0 0 15px;
  font-size: 18px;
}

._5JC__q_info:last-child {
  margin: 0;
}

@media (width <= 425px) {
  ._5JC__q_info {
    max-width: 320px;
    font-size: 15px;
  }
}

._5JC__q_screenshot {
  image-rendering: high-quality;
  border: 1px solid #ff9046;
  border-radius: 36px;
  width: 300px;
  height: 540px;
  margin: 0 30px 60px;
}

._5JC__q_timeline {
  margin: 0 12px 60px;
}

._5JC__q_follow {
  margin: 30px;
}

._5JC__q_shared {
  word-wrap: break-word;
  border-radius: 6px;
  flex-direction: column;
  width: 100%;
  padding: .9rem;
  line-height: 1.2rem;
  display: flex;
}

@media (width <= 800px) {
  ._5JC__q_shared {
    line-height: .9rem;
  }
}

._5JC__q_sent {
  color: #fff;
  background-color: #ff904633;
  align-self: flex-end;
}

._5JC__q_received {
  color: #fff;
  background-color: #814d3133;
  align-self: flex-start;
}
/*# sourceMappingURL=index.03d2410e.css.map */
